<template>
  <div>
    <Dialog
      :visible.sync="emailCompose.show"
      :containerStyle="{ width: $isMobile() ? '100vw' : '40vw' }"
      :position="'bottomright'"
      :maximizable="true"
      v-on:hide="cancelSend"
      :header="emailCompose.header"
    >
      <div class="p-col-12">
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>Para</b>
          </div>
          <div class="p-col-11">
            <Chips
              :allowDuplicate="false"
              style="width: 100%"
              v-bind:class="[
                {
                  'p-invalid': emailCompose.response.errorTo,
                },
                'p-ml-1',
              ]"
              separator=","
              v-model="emailCompose.response.to"
            >
              <template #chip="slotProps">
                <div class="p-d-flex p-ai-center">
                  <i class="pi pi-user p-mr-1 circle"></i>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
            <small
              v-if="emailCompose.response.errorTo"
              class="p-error"
              role="alert"
            >
              Destinatário está vazio
            </small>
          </div>
        </div>
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>CC</b>
          </div>
          <div class="p-col-11">
            <Chips
              :allowDuplicate="false"
              style="width: 100%"
              separator=","
              v-model="emailCompose.response.cc"
            >
              <template #chip="slotProps">
                <div class="p-d-flex p-ai-center">
                  <i class="pi pi-user p-mr-1 circle"></i>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
          </div>
        </div>
        <div class="p-col-12">
          <Editor
            name="body"
            v-model="emailCompose.response.content"
            editorStyle="height: 200px"
            v-bind:class="[
              { 'p-invalid': emailCompose.response.errorContent },
              'form-control',
            ]"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
          <small
            class="p-error"
            role="alert"
            v-if="emailCompose.response.errorContent"
          >
            O email está vazio
          </small>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <FileUpload
            :ref="'attachmentInput'"
            :name="'attachments[]'"
            chooseLabel="Adicionar Anexo"
            :multiple="true"
            :showUploadButton="false"
            :showCancelButton="false"
            :maxFileSize="2000000000"
          />
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-5">
            <span class="p-float-label">
              <Dropdown
                :name="'ticket-status'"
                :options="status"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'status'"
                v-model="emailCompose.email.newstatus"
                v-validate="'required'"
                style="width: 100%"
                v-bind:class="[
                  { 'p-invalid': emailCompose.response.errorStatus },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label :for="'ticket-status'"
                >Selecionar Estado da Proposta</label
              >
            </span>
            <small
              v-if="emailCompose.response.errorStatus"
              class="p-error"
              role="alert"
            >
              Novo Estado é obrigatório
            </small>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="'Enviar'"
          icon="pi pi-send"
          class="p-button-sm p-button-success"
          @click="sendEmail()"
        />
        <Button
          :label="'Cancelar'"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="cancelSend()"
        />
      </template>
    </Dialog>
    <div
      class="p-col-12 card p-shadow-3"
      :style="{ position: 'relative', height: '80vh' }"
      v-if="!proposalInfo"
    >
      <Loading :active="true" :isFullPage="false"></Loading>
    </div>
    <div
      class="p-grid p-col-12 p-lg-12"
      v-else
      :style="{ position: 'relative' }"
    >
      <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
      <Loading :active.sync="loading" :isFullPage="false"></Loading>
      <ConfirmDialog />
      <ChangeResponsible
        :showToast="true"
        :show="setResposible"
        :proposalId="proposalInfo.id"
        :proposalSubject="proposalInfo.subject"
        :currentTechnician="proposalInfo.assigned_to"
        v-on:cancel="setResposible = false"
        v-on:changeTechnicianResp="changeResposibleOk"
      />
      <ChangeCategory
        :showToast="true"
        :show="setCategory"
        :proposalId="proposalInfo.id"
        :proposalSubject="proposalInfo.subject"
        :categoryId="proposalInfo.caetgory"
        v-on:cancel="setCategory = false"
        v-on:changedProposalCategory="changeCategoryOk"
      />
      <ChangePriority
        :showToast="true"
        :show="setPriority"
        :proposalId="proposalInfo.id"
        :proposalSubject="proposalInfo.subject"
        :priorityId="proposalInfo.priority"
        v-on:cancel="setPriority = false"
        v-on:changedProposalPriority="changePriorityOk"
      />
      <SetStatus
        :showToast="true"
        :newStatusId="setStatus"
        :proposalId="proposalInfo.id"
        :menssage="proposalInfo.subject"
        :proposalName="proposalInfo.subject"
        v-on:cancel="setStatus = false"
        v-on:changedProposalStatus="setStatusOk"
      />
      <AddClientContact
        :showToast="true"
        :show="addClientContact"
        :clientId="proposalInfo.client_id"
        v-on:cancel="addClientContact = false"
        v-on:contactAdded="contactAddedOk"
      />
      <ProposalSetBudget
        :showToast="true"
        :changeBudgetedStatus="changeBudgetStatus"
        :budgeted="proposalInfo.budgeted"
        :proposalId="proposalInfo.id"
        :proposalName="proposalInfo.subject"
        v-on:cancel="changeBudgetStatus = false"
        v-on:setBudgetProposal="setProposalBudgetedOk"
      />
      <AddNote
        :showToast="true"
        :show="addNote"
        :proposalId="proposalInfo.id"
        v-on:cancel="addNote = false"
        v-on:addedPropNote="noteAdded"
      />
      <RemoveNote
        :showToast="true"
        :deleteNote="noteToDelete.show"
        :proposalId="noteToDelete.proporsalId"
        :noteId="noteToDelete.noteId"
        v-on:cancel="deleteNoteCancel"
        v-on:proposalNoteDeleted="deleteNoteOk"
      />
      <ImportProposal
        :showToast="true"
        :show="importProposal"
        :proposalId="proposalInfo.id"
        :proposalSubject="proposalInfo.subject"
        v-on:cancel="importProposal = false"
        v-on:importedProposal="importProposalOk"
      />
      <div class="p-col-12 card p-shadow-3 p-d-flex p-ai-center">
        <h3>Proposta {{ proposalInfo.id }} | {{ proposalInfo.subject }}</h3>
      </div>
      <div
        v-bind:class="[
          { 'p-md-12': proposalId },
          { 'p-md-6': !proposalId },
          'p-col-12',
        ]"
        class="p-col-12 p-md-6"
      >
        <div class="p-col-12 card p-shadow-3">
          <Button
            v-if="
              (proposalInfo.type_id != 1 || proposalInfo.pat_id == null) &&
              proposalInfo.statusName != 'Cancelado'
            "
            :label="'Cancelar'"
            icon="pi pi-ban"
            class="p-button-danger p-mr-1"
            @click="setStatus = 6"
          />
          <Button
            v-if="
              proposalInfo.type_id != 1 &&
              proposalInfo.status != 2 &&
              proposalInfo.status != 5 &&
              proposalInfo.status != 6
            "
            :label="'Pendente'"
            icon="pi pi-pause"
            class="p-button-warning p-mr-1"
            @click="setStatus = 2"
          />
          <Button
            v-if="
              proposalInfo.type_id != 1 &&
              (proposalInfo.status == 2 || proposalInfo.status == 1)
            "
            :label="'Retomar'"
            icon="pi pi-play"
            class="p-button-success p-mr-1"
            @click="setStatus = 4"
          />
          <Button
            v-if="
              proposalInfo.type_id != 1 &&
              proposalInfo.status != 2 &&
              proposalInfo.status != 5 &&
              proposalInfo.status != 6 &&
              proposalInfo.status != 1
            "
            :label="'Aguarda Resposta'"
            icon="pi pi-spinner"
            class="p-button-success p-mr-1"
            @click="setStatus = 1"
          />
          <Button
            v-if="proposalInfo.type_id == 2"
            v-tooltip="'Alterar estado Orçamento'"
            :label="
              proposalInfo.budgeted == 1
                ? 'Marcar Como Não Orçamentado'
                : 'Marcar Como Orçamentado'
            "
            icon="pi pi-euro"
            class="p-button-warning p-mr-1"
            @click="changeBudgetStatus = true"
          />
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Estado</b></div>
            <div class="p-col-10">
              <b>{{ proposalInfo.statusName }}</b>
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Cliente</b></div>
            <div class="p-col-10">
              <Dropdown
                v-model="proposalInfo.client_id"
                placeholder="Selecione o Cliente"
                :options="clientsList"
                optionValue="id"
                dataKey="reference"
                :optionLabel="searchLabelName"
                class="p-column-filter"
                :filter="true"
                :showClear="true"
                :style="{
                  width: '80%',
                }"
                @change="hasChange = true"
              >
              </Dropdown>
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Cliente Contacto</b></div>
            <div class="p-col-10">
              <Dropdown
                v-model="proposalInfo.contact_id"
                placeholder="Selecione o Contacto do Cliente"
                :options="contactsList"
                optionValue="id"
                dataKey="reference"
                :optionLabel="contactsListLabel"
                class="p-column-filter"
                :filter="true"
                :showClear="true"
                :style="{
                  width: '80%',
                }"
                @change="hasChange = true"
              >
              </Dropdown>
              <Button
                icon="pi pi-plus"
                class="p-button-rounded p-button-text p-button-success"
                v-tooltip="'Adicionar Contacto de Cliente'"
                @click="addClientContact = true"
              />
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Vendedor</b></div>
            <div class="p-col-10">
              <Dropdown
                v-model="proposalInfo.seller_id"
                placeholder="Selecione o Vendedor"
                :options="sellersList"
                optionValue="id"
                dataKey="id"
                :optionLabel="searchLabelName"
                class="p-column-filter"
                :filter="true"
                :showClear="true"
                :style="{
                  width: '80%',
                }"
                @change="hasChange = true"
              >
              </Dropdown>
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Tipo de Proposta</b></div>
            <div class="p-col-10">
              <Dropdown
                v-model="proposalInfo.type_id"
                placeholder="Selecione o Tipo de Proposta"
                :options="typesList"
                optionValue="id"
                dataKey="id"
                optionLabel="name"
                class="p-column-filter"
                :filter="true"
                :showClear="true"
                :style="{
                  width: '80%',
                }"
                @change="hasChange = true"
              >
              </Dropdown>
            </div>
          </div>
          <div
            class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Morada</b></div>
            <div class="p-col-10">
              <Textarea
                :autoResize="true"
                v-model="proposalInfo.address"
                :style="{
                  width: '80%',
                }"
                v-bind:class="['p-inputtextarea', 'p-inputtext', 'p-component']"
                rows="1"
                @input="hasChange = true"
              />
            </div>
          </div>
          <div
            class="p-d-flex p-ai-center p-mt-1"
            v-if="proposalInfo.type_id == 1"
          >
            <div class="p-col-2"><b>Pat n.º</b></div>
            <div class="p-col-10">
              <InputNumber
                v-model="proposalInfo.pat_id"
                :useGrouping="false"
                :min="0"
                :style="{
                  width: '80%',
                }"
                @input="hasChange = true"
              />
            </div>
          </div>
          <div
            class="p-d-flex p-ai-center p-mt-1"
            v-if="proposalInfo.type_id == 1"
          >
            <div class="p-col-2"><b>Contactos Survey</b></div>
            <div class="p-col-10">
              <Textarea
                :autoResize="true"
                v-model="proposalInfo.pat_contacts"
                :style="{
                  width: '80%',
                }"
                v-bind:class="['p-inputtextarea', 'p-inputtext', 'p-component']"
                rows="3"
                @input="hasChange = true"
              />
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Descrição</b></div>
            <div class="p-col-10">
              <Textarea
                :autoResize="true"
                v-model="proposalInfo.description"
                :style="{
                  width: '80%',
                }"
                v-bind:class="['p-inputtextarea', 'p-inputtext', 'p-component']"
                rows="5"
                @input="hasChange = true"
              />
            </div>
          </div>
          <div
            class="p-d-flex p-ai-center p-mt-1"
            v-if="proposalInfo.type_id == 1 && proposalInfo.notification_send"
          >
            <div class="p-col-2"><b>Notificação Criação Survey</b></div>
            <div class="p-col-10">
              {{ proposalInfo.notification_send }}
            </div>
          </div>
          <div
            class="p-d-flex p-ai-center p-mt-1"
            v-if="proposalInfo.type_id == 2"
          >
            <div class="p-col-2"><b>Orçamentado</b></div>
            <div class="p-col-10">
              {{ proposalInfo.budgeted == 1 ? "Sim" : "Não" }}
            </div>
          </div>
          <div
            class="p-d-flex p-ai-center p-mt-1"
            v-if="proposalInfo.created_at"
          >
            <div class="p-col-2"><b>Criado</b></div>
            <div class="p-col-10">
              {{
                proposalInfo.created_by == 0
                  ? "Intranet"
                  : proposalInfo.proposal_created_user.username
              }}
              | {{ getDateTime(proposalInfo.created_at) }}
            </div>
          </div>
          <div
            class="p-d-flex p-ai-center p-mt-1"
            v-if="proposalInfo.updated_at"
          >
            <div class="p-col-2"><b>Atualizado</b></div>
            <div
              class="p-col-10"
              v-if="
                proposalInfo.updated_at &&
                proposalInfo.updated_at != proposalInfo.created_at
              "
            >
              {{
                proposalInfo.updated_by == 0
                  ? "Intranet"
                  : proposalInfo.proposal_updated_user.username
              }}
              | {{ getDateTime(proposalInfo.updated_at) }}
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2 p-d-flex p-jc-between">
              <b>Responsável Orçamento</b
              ><i
                v-if="!proposalInfo.assigned_to"
                class="pi pi-exclamation-triangle p-ml-2 p-error"
              ></i>
            </div>
            <div
              v-bind:class="[
                { error: !proposalInfo.assigned_to },
                'p-col-10',
                'clickable-item',
              ]"
              @click="setResposible = true"
            >
              {{
                proposalInfo.proposal_assigned_user
                  ? proposalInfo.proposal_assigned_user.username
                  : "(Sem Responávél Associado)"
              }}
            </div>
          </div>
          <!-- <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Estado</b></div>
            <div class="p-col-10 clickable-item" @click="setStatus = true">
              {{ proposalInfo.proposal_status.name }}
            </div>
          </div> -->
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2"><b>Prioridade</b></div>
            <div class="p-col-10 clickable-item" @click="setPriority = true">
              {{ proposalInfo.proposal_priority.name }}
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mt-1">
            <div class="p-col-2 p-d-flex p-jc-between">
              <b>Categoria</b
              ><i
                v-if="!proposalInfo.category"
                class="pi pi-exclamation-triangle p-error"
              ></i>
            </div>
            <div
              v-bind:class="[
                { error: !proposalInfo.category },
                'p-col-10',
                'clickable-item',
              ]"
              @click="setCategory = true"
            >
              {{
                proposalInfo.category
                  ? proposalInfo.proposal_category.name
                  : "(Sem Categoria Associada)"
              }}
            </div>
          </div>
          <divider />
          <Button
            v-if="hasChange"
            icon="pi pi-save"
            label="Gravar"
            class="p-button-sm p-button-success p-mr-1"
            @click="saveProposal()"
          />
          <Button
            v-if="
              !hasChange &&
              proposalInfo.client_id &&
              proposalInfo.contact_id &&
              proposalInfo.seller_id &&
              proposalInfo.type_id == 1 &&
              proposalInfo.description
            "
            icon="pi pi-send "
            label="Solicitar Criação Survey"
            class="p-button-sm p-button-warning p-mr-2"
            @click="requestSurvey()"
          />
          <Button
            v-tooltip="'Importar outras propostas para esta'"
            label="Importar Propostas"
            icon="pi pi-directions-alt"
            class="p-button-sm p-button-warning"
            @click="importProposal = true"
          />
        </div>
        <div
          class="p-col-12 card p-shadow-3"
          v-if="$store.state.auth.user.isEsa || can('seeProposalsNotes')"
        >
          <Accordion :multiple="true">
            <AccordionTab class="accordion-custom">
              <template #header>
                <div style="font-size: large">
                  <i class="pi pi-comments p-mr-2"></i>Notas
                </div>
              </template>
              <Button
                v-if="$store.state.auth.user.isEsa || can('addProposalsNotes')"
                label="Adicionar Nota"
                icon="pi pi-plus"
                class="p-button-success"
                @click="addNote = true"
              />
              <divider />
              <PrimeVueTimeline
                :value="proposalInfo.proposal_notes"
                align="left"
                class="customized-timeline"
              >
                <template #marker>
                  <span class="custom-marker p-shadow-4 p-mt-3">
                    <i :class="'pi pi-comment'"></i>
                  </span>
                </template>
                <template #content="slotProps">
                  <Card class="p-shadow-4 p-mt-2">
                    <template #title>
                      {{
                        slotProps.item.user_created != null
                          ? slotProps.item.user_created.username
                          : ""
                      }}
                    </template>
                    <template #subtitle>
                      {{ getDateTime(slotProps.item.created_at) }} |
                      {{
                        slotProps.item.is_public == true ? "Pública" : "Interna"
                      }}
                    </template>
                    <template #content>
                      <div v-html="slotProps.item.note"></div>
                      <div class="p-d-flex p-jc-end">
                        <Button
                          v-if="
                            $store.state.auth.user.isEsa ||
                            can('deleteProposalsNotes')
                          "
                          icon="pi pi-trash"
                          class="p-button-danger p-button-sm"
                          v-tooltip="'Apagar Nota'"
                          @click="deleteNote(slotProps)"
                        />
                      </div>
                    </template>
                  </Card>
                </template>
              </PrimeVueTimeline>
            </AccordionTab>
          </Accordion>
        </div>
        <div class="p-col-12">
          <Accordion :activeIndex="activeEmailIndex">
            <AccordionTab
              class="accordion-custom"
              v-for="email in proposalInfo.proposal_messages"
              :key="email.id"
            >
              <template #header>
                <div
                  class="p-d-flex p-jc-between"
                  style="width: 100%"
                  @click="tabOpen(email)"
                >
                  <div>
                    <b>{{ email.email_from }}</b>
                  </div>
                  <div>
                    <i
                      class="pi pi-eye-slash p-mr-1"
                      v-tooltip="'Não Lido'"
                      v-if="email.status == 1"
                    ></i>
                    <i
                      class="pi pi-eye p-mr-1"
                      v-tooltip="'Lido'"
                      v-if="email.status == 2"
                    ></i>
                    <i
                      class="pi pi-send p-mr-1"
                      v-tooltip="'Enviado'"
                      v-if="email.status == 3"
                    ></i>
                    <i
                      class="pi pi-paperclip p-mr-1"
                      v-tooltip="'Anexos'"
                      v-if="email.message_attachments.length > 0"
                    ></i>
                    {{ email.received_on }}
                  </div>
                </div>
              </template>
              <div>
                <div class="card p-shadow-3 p-grid" v-if="email.status < 4">
                  <div class="p-col-10">
                    <div>
                      <b>De:</b> {{ email.email_from }} |
                      {{ email.received_on }}
                    </div>
                    <div class="p-mt-1"><b>Para:</b> {{ email.email_to }}</div>
                    <div class="p-mt-1" v-if="email.email_cc != ''">
                      <b>CC:</b> {{ email.email_cc }}
                    </div>
                  </div>
                  <div class="p-col-2 p-d-flex p-ai-end p-flex-column">
                    <div>
                      <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text p-button-danger"
                        v-tooltip="'Eliminar Email'"
                        @click="removeMessage(email)"
                      />
                      <Button
                        icon="pi pi-reply"
                        class="p-button-rounded p-button-text"
                        v-tooltip="'Reencaminhar'"
                        @click="forwardEmail(email)"
                      />
                      <Button
                        icon="pi pi-reply"
                        class="p-button-rounded p-button-text invert"
                        v-tooltip="'Responder'"
                        @click="responseEmail(email)"
                      />
                    </div>
                  </div>
                  <div class="p-col-12">
                    <divider />
                    <div v-html="email.html" style="overflow-x: auto"></div>
                    <div v-if="email.message_attachments.length > 0">
                      <divider />
                      <i class="pi pi-paperclip"></i><b>Anexos:</b>
                      <div>
                        <Button
                          class="p-button-sm p-button-rounded p-ml-1 p-mt-1"
                          v-for="attachment in email.message_attachments"
                          :key="attachment.id"
                          :label="attachment.name"
                          icon="pi pi-file"
                          @click="downloadAttachments(email.id, attachment.id)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>

      <div
        v-bind:class="[
          { 'p-md-12': proposalId },
          { 'p-md-6': !proposalId },
          'p-col-12',
        ]"
        class="p-col-12 p-md-6"
      >
        <div class="p-col-12 card p-shadow-3" v-if="proposalInfo.statusName == 'Survey'">
          <PatDetails
            :patId="proposalInfo.pat_id"
            :compact="true"
          />

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import proposalService from "../services/proposal.service";
import clientService from "../services/clients.services";
import externalManagerService from "../services/externalManager.service";
import prospectService from "../services/prospect.service";
import messagesService from "../services/proposalEmails.service";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import ChangeResponsible from "../components/proposal/ChangeResponsible.vue";
import ChangeCategory from "../components/proposal/ChangeCategory.vue";
import ChangePriority from "../components/proposal/ChangePriority.vue";
import SetStatus from "../components/proposal/SetStatus.vue";
import AddClientContact from "../components/proposal/AddClientContact.vue";
import ProposalSetBudget from "../components/proposal/SetBudget.vue";
import AddNote from "../components/proposal/AddNote.vue";
import RemoveNote from "../components/proposal/DeleteNote.vue";
import ImportProposal from "../components/proposal/ImportProposal.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PrimeVueTimeline from "primevue/timeline";
import unidecode from "unidecode";
import PatDetails from "./PatDetails.vue";
export default {
  name: "ProposalDetail",
  props: ["proposalId"],
  components: {
    PrimeVueTimeline,
    ChangeResponsible,
    ChangeCategory,
    ChangePriority,
    SetStatus,
    AddClientContact,
    Loading,
    ProposalSetBudget,
    AddNote,
    RemoveNote,
    ImportProposal,
    PatDetails,
  },
  data() {
    return {
      loading: true,
      proposalInfo: null,
      setResposible: false,
      setCategory: false,
      setPriority: false,
      setStatus: false,
      addClientContact: false,
      changeBudgetStatus: false,
      addNote: false,
      importProposal: false,
      noteToDelete: {
        show: false,
        proporsalId: null,
        noteId: null,
        noteIndex: null,
      },
      activeEmailIndex: 0,
      status: [],
      hasChange: false,
      clientsList: [],
      sellersList: [],
      typesList: [],
      contactsList: [],
      emailCompose: {
        show: false,
        header: null,
        response: {
          to: [],
          cc: [],
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
        email: {},
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async mounted() {
    this.proposalInfo = await this.getProposalInfo();
    this.proposalInfo.client_id = `${this.proposalInfo.client_id}`;
    if (!this.proposalInfo.proposal_messages) {
      this.proposalInfo.proposal_messages = [];
    }

    this.status = await this.getStatus();
    this.loading = false;
  },
  async created() {
    this.loading = true;
    this.clientsList = await clientService.getAllClients();
    this.sellersList = await prospectService.getSellers();
    this.typesList = await proposalService.getTypesList();
    this.contactsList = await externalManagerService.getAll();
    this.loading = false;
  },
  methods: {
    getStatus() {
      return proposalService.getStatusList().then((response) => {
        let status = response.filter(
          (state) => state.status >= 1 && state.status <= 5
        );
        return status;
      });
    },
    async getProposalInfo() {
      const processedHtml = (email) => {
        let el = new DOMParser().parseFromString(email.body_html, "text/html");
        [...email.message_attachments_inline].forEach((inlineImg) => {
          let image;
          if (inlineImg.content_id) {
            image = el.querySelector(`img[src$='${inlineImg.content_id}' i]`);
          } else {
            image = el.querySelector(`img[alt$='${inlineImg.name}']`);
          }
          if (!image) {
            email.message_attachments.push({
              id: inlineImg.id,
              name: inlineImg.name,
            });
          } else {
            image.src = `data:${inlineImg.mimetype};${inlineImg.encoding},${inlineImg.data}`;
          }
        });
        return el.documentElement.innerHTML;
      };
      let idProposal = this.$route.params.proposalId
        ? this.$route.params.proposalId
        : this.proposalId;
      return proposalService.getProposalInfo(idProposal).then((response) => {
        response.proposal_messages = response.proposal_messages.map((v) => ({
          ...v,
          showCompose: false,
          newstatus: null,
          response: {
            to: [],
            cc: [],
            content: "",
            errorStatus: false,
            errorContent: false,
            errorTo: false,
          },
          html: processedHtml(v),
        }));
        return response;
      });
    },
    getInfoFromEmail(email) {
      if (!email) {
        return "";
      }
      let info = email.replace("@", " | ");
      info = info.replace(/[.,-/_]/g, " ");
      return info.substring(0, info.lastIndexOf(" "));
    },
    getDateTime(date) {
      let auxDate = new Date(date);
      return `${getOnlyDate(auxDate)} ${getOnlyTime(auxDate)}`;
    },
    changeResposibleOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.assigned_to = response.result.technician_id;
        this.proposalInfo.proposal_assigned_user = {
          userno: response.result.responsible_user_id,
          username: response.result.responsible_user,
          tecnico: response.result.technician_id,
        };
      }
      return (this.setResposible = false);
    },
    changeCategoryOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.category = response.result.category_id;
        this.proposalInfo.proposal_category = {
          id: response.result.category_id,
          name: response.result.category_name,
        };
      }
      return (this.setCategory = false);
    },
    changePriorityOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.priority = response.result.priority_id;
        this.proposalInfo.proposal_priority = {
          priority: response.result.priority_id,
          name: response.result.priority_name,
        };
      }
      return (this.setPriority = false);
    },
    changeStatusOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.status = response.result.status_id;
        this.proposalInfo.proposal_status = {
          status: response.result.status_id,
          name: response.result.status_name,
        };
      }
      return (this.setStatus = false);
    },
    setStatusOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.statusName = response.currentStatus;
        this.proposalInfo.status = response.currentStatusId;
      }
      return (this.setStatus = false);
    },
    contactAddedOk(response) {
      if (response.status == "ok") {
        this.contactsList.push(response.contact);
        this.proposalInfo.contact_id = response.contact.id;
      }
      return (this.addClientContact = false);
    },
    setMessageStatus(email, status) {
      return messagesService
        .setStatus(email.id, { status_id: status })
        .then((response) => {
          email.status = response.status_id;
          email.message_status.status = response.status_id;
          email.message_status.name = response.status_name;
        });
    },
    removeMessage(email) {
      this.$confirm.require({
        header: "Eliminar Email",
        message: "Tem a certeza que deseja eliminar este email?",
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        accept: () => {
          return this.setMessageStatus(email, 4);
        },
        reject: () => {},
      });
    },
    downloadAttachments(emailId, attachmentId) {
      return messagesService
        .getAttachment(emailId, attachmentId)
        .then((response) => {
          let linkSource = `data:${response.mimetype};base64,${response.data}`;
          let downloadLink = document.createElement("a");
          let fileName = response.name;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    },
    tabOpen(email) {
      if (email.status == 1) {
        return this.setMessageStatus(email, 2);
      }
    },
    forwardEmail(email) {
      let cc = [];
      if (email.email_cc.includes(", ")) {
        let ccAux = email.email_cc.replace(/\s/g, "");
        if (ccAux != "") {
          cc = ccAux.split(",");
        }
      }
      if (!email.email_cc.includes("propostas@w4msolutions.com")) {
        cc.push("propostas@w4msolutions.com");
      }
      email.newstatus = null;
      return (this.emailCompose = {
        show: true,
        header: "Reencaminhar Email",
        email: email,
        response: {
          to: [],
          cc: cc,
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
      });
    },
    responseEmail(email) {
      let cc = [];
      if (email.email_cc != "''") {
        let ccAux = email.email_cc.replace(/\s/g, "");
        if (ccAux != "") {
          cc = ccAux.split(",");
        }
      }
      if (!email.email_cc.includes("propostas@w4msolutions.com")) {
        cc.push("propostas@w4msolutions.com");
      }
      let to = email.email_from.replace(/\s/g, "");
      email.newstatus = null;
      return (this.emailCompose = {
        show: true,
        header: "Responder a Email",
        email: email,
        response: {
          to: to.split(", "),
          cc: cc,
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
      });
    },
    cancelSend() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.emailCompose = {
        show: false,
        response: {
          to: [],
          cc: [],
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
        email: {},
      });
    },
    sendEmail() {
      let hasError = false;
      if (this.emailCompose.response.content == "") {
        hasError = true;
        this.emailCompose.response.errorContent = true;
      } else {
        this.emailCompose.response.errorContent = false;
      }
      if (!this.emailCompose.email.newstatus) {
        hasError = true;
        this.emailCompose.response.errorStatus = true;
      } else {
        this.emailCompose.response.errorStatus = false;
      }
      if (this.emailCompose.response["to"].length == 0) {
        hasError = true;
        this.emailCompose.response.errorTo = true;
      } else {
        this.emailCompose.response.errorTo = false;
      }
      if (hasError) {
        return;
      }

      var formData = new FormData();
      formData.append("email_id", this.emailCompose.email.id);
      formData.append("new_state", this.emailCompose.email.newstatus);
      formData.append("to", this.emailCompose.response["to"]);
      formData.append("cc", this.emailCompose.response.cc);
      formData.append("body", this.emailCompose.response.content);
      let attachments = this.$refs["attachmentInput"];

      if (attachments.files.length > 0) {
        attachments.files.forEach((file) => {
          formData.append("attachments[]", file);
        });
      }
      this.$validator.pause();
      this.$validator.reset();
      this.loading = true;
      return proposalService
        .sendMessage(this.proposalInfo.id, formData)
        .then((response) => {
          this.proposalInfo.status = response.status_id;
          this.proposalInfo.proposal_status = {
            status: response.status_id,
            name: response.status_name,
          };
          this.loading = false;
          return this.cancelSend();
        });
    },
    contactsListLabel(item) {
      let label = `${item.name}`;
      if (item.email != "") {
        label += ` | ${item.email}`;
      }
      if (item.telephone != "") {
        label += ` | ${item.telephone.replace(" ", "")}`;
      }
      if (item.type != "") {
        label += ` | ${item.type}`;
      }
      return label;
    },
    saveProposal() {
      let params = {};
      if (this.proposalInfo.client_id) {
        params.client_id = parseInt(this.proposalInfo.client_id);
      }

      if (this.proposalInfo.contact_id) {
        params.contact_id = this.proposalInfo.contact_id;
      }

      if (this.proposalInfo.seller_id) {
        params.seller_id = parseInt(this.proposalInfo.seller_id);
      }

      if (this.proposalInfo.type_id) {
        params.type_id = this.proposalInfo.type_id;
      }

      if (this.proposalInfo.pat_id) {
        params.pat_id = this.proposalInfo.pat_id;
      }

      if (this.proposalInfo.pat_contacts) {
        params.pat_contacts = this.proposalInfo.pat_contacts;
      }

      if (this.proposalInfo.address) {
        params.address = this.proposalInfo.address;
      }

      if (this.proposalInfo.description) {
        params.description = this.proposalInfo.description;
      }

      return proposalService
        .update(this.proposalInfo.id, params)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao atualizar a proposta",
              detail: "",
              life: 3000,
            });
          }
          this.hasChange = false;
          return this.$toast.add({
            severity: "success",
            summary: `Proposta actualizada com sucesso`,
            detail: ``,
            life: 3000,
          });
        });
    },
    requestSurvey() {
      return proposalService
        .requestSurvey(this.proposalInfo.id)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao ao enviar o pedido de survey",
              detail: "",
              life: 3000,
            });
          }
          this.hasChange = false;
          this.proposalInfo.notification_send = response.notification_send;
          return this.$toast.add({
            severity: "success",
            summary: `Pedido de survey enviado com sucesso`,
            detail: ``,
            life: 3000,
          });
        });
    },
    setProposalBudgetedOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.budgeted = response.currentStatus;
      }
      return (this.changeBudgetStatus = false);
    },
    noteAdded(response) {
      if (response.status == "ok") {
        this.proposalInfo.proposal_notes.unshift(response.note);
      }
      return (this.addNote = false);
    },
    deleteNote(noteInfo) {
      return (this.noteToDelete = {
        proporsalId: this.proposalInfo.id,
        noteId: noteInfo.item.id,
        noteIndex: noteInfo.index,
        show: true,
      });
    },
    deleteNoteCancel() {
      return (this.noteToDelete = {
        proporsalId: null,
        noteId: null,
        noteIndex: null,
        show: false,
      });
    },
    deleteNoteOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.proposal_notes.splice(this.noteToDelete.noteIndex, 1);
      }
      return this.deleteNoteCancel();
    },
    importProposalOk(response) {
      if (response.status == "ok") {
        let route = this.$router.resolve({
          path: `/proposal-detail/${response.destinationProposalId}`,
        });
        window.open(route.href, "_self");
      }
    },
    searchLabelName(info) {
      return `${unidecode(info.name)}`;
    },
  },
};
</script>
<style>
.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}
.error {
  color: #dc3545 !important;
}
.p-inputtext.p-chips-multiple-container {
  display: block !important;
  width: 100% !important;
}

.invert {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.p-multiselect-label {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}

.custom-marker {
  display: -webkit-;
  display: -ms-flexbox;
  display: flex;
  width: 2rem;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
  background-color: #ff4910;
}
.p-timeline-event-opposite {
  display: none !important;
}
.p-accordion-header-link {
  background-color: #474a54 !important;
  color: #fff !important;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.p-chips-token {
  background-color: #fff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #3498db !important;
  margin-bottom: 2px;
}
</style>
